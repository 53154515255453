<template>
    <div class="vstack gap-3">
        <div class="vstack bg-white rounded shadow-sm">
            <div class="hstack p-3 justify-content-between border-bottom">
                <div>
                    <div class="hstack gap-3">
                        <div class="vstack justify-content-center">
                            <div class="fw-semibold fs-5">
                                Lista de Produtos
                            </div>
                            <div class="small-text text-secondary">
                                {{ this.allProductsCount }} Produtos Cadastrados
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <b-button variant="green" class="text-white rounded-pill py-1 px-3" v-if="!user.channelConfig?.modules?.productsFromIntegration">Cadastrar Produtos</b-button>
                    <b-button variant="warning" class="text-white rounded-pill py-1 px-3" v-b-modal.hideCategory>Ocultar Categoria/Subcategoria</b-button>
                </div>
            </div>
            <div class="hstack p-3 gap-3 align-items-end">
                <b-form-group class="w-100" label="Categoria:">
                    <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="selectedCategoryId" @change="selectedSubcategoryId = null">
                        <b-form-select-option :value="null">
                            Todas
                        </b-form-select-option>
                        <b-form-select-option :value="category._id" v-for="category in categories.filter(el => !el.topCategoryId)" :key="category._id">
                            {{ category.name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group class="w-100" label="Subcategoria:">
                    <b-form-select class="text-secondary w-100 rounded p-2 border-grey" :disabled="!selectedCategoryId" v-model="selectedSubcategoryId" v-if="!categories.filter(el => el.topCategoryId == selectedCategoryId).length">
                        <b-form-select-option :value="0">
                            Nenhuma subcategoria encontrada!
                        </b-form-select-option>
                    </b-form-select>
                    <b-form-select class="text-secondary w-100 rounded p-2 border-grey" :disabled="!selectedCategoryId" v-model="selectedSubcategoryId" v-else>
                            <b-form-select-option :value="subcategory._id" v-for="subcategory in categories.filter(el => el.topCategoryId == selectedCategoryId)" :key="subcategory._id">
                                {{ subcategory.name }}
                            </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group class="w-100" label="Visualizar:">
                    <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="filters.hidden">
                        <b-form-select-option :value="null">Todos</b-form-select-option>
                        <b-form-select-option :value="true">Ocultos</b-form-select-option>
                        <b-form-select-option :value="false">Não Ocultos</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-button variant="green" class="text-white" :disabled="selectedCategoryId && !selectedSubcategoryId" @click="getProducts(1)">
                    Filtrar
                </b-button>
            </div>
        </div>
        <div v-if="hasLoaded">
            <div class="hstack align-items-center justify-content-center p-5" v-if="loadingProducts">
                <b-spinner />
            </div>
            <div v-else-if="products.length">
                <b-row class="m-0" cols="1" cols-sm="2" cols-md="4">
                    <b-col class="p-0 pb-3 productsCol" v-for="product in products" :key="product._id">
                        <b-overlay class="h-100" :show="product.hidden || products.categoryIsHidden" :opacity=".5" no-center>
                            <template #overlay>
                                <div class="position-relative" v-if="!products.categoryIsHidden">
                                    <div class="position-absolute top-0 end-0 p-4 vstack gap-2">
                                        <!-- <div v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" role="button" v-if="!user.channelConfig.modules.productsFromIntegration">
                                            <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                                <b-icon stacked icon="pencil-fill" scale="0.5" variant="white"></b-icon>
                                            </b-iconstack>
                                        </div> -->
                                        <div v-b-tooltip.hover.bottom="{ variant: product.hidden ? 'primary' : 'warning', customClass: 'tooltip-text-white top-0', boundary: 'document' }" :title="product.hidden ? 'Desocultar' : 'Ocultar'" role="button" v-b-modal.hideProduct @click="selectProduct(product)">
                                            <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                <b-icon stacked icon="circle-fill" :variant="product.hidden ? 'primary' : 'warning'"></b-icon>
                                                <b-icon stacked :icon="product.hidden ? 'eye' : 'eye-slash'" scale="0.5" variant="white"></b-icon>
                                            </b-iconstack>
                                        </div>
                                        <div v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'text-white top-0', boundary: 'document' }" title="Editar" role="button" v-b-modal.productEdit @click="selectProduct(product, true)">
                                            <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                                <b-icon stacked icon="pencil-fill" scale=".4" variant="white"></b-icon>
                                            </b-iconstack>
                                        </div>
                                        <div v-b-tooltip.hover.bottom.v-info="{ customClass: 'text-white top-0', boundary: 'document' }" title="Visualizar" role="button" v-b-modal.productDetails @click="selectProduct(product)">
                                            <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                                                <b-icon stacked icon="info" scale=".75" variant="white"></b-icon>
                                            </b-iconstack>
                                        </div>
                                        <div v-b-tooltip.hover.bottom.v-danger="{ customClass: 'text-white top-0', boundary: 'document' }" title="Remover" role="button" v-if="!user.channelConfig.modules.productsFromIntegration">
                                            <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                                                <b-icon stacked icon="trash" scale="0.5" variant="white"></b-icon>
                                            </b-iconstack>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center text-secondary fs-5 h-100" v-else>
                                    <span class="bg-secondary-subtle rounded p-2">
                                        Categoria Oculta
                                    </span>
                                </div>
                            </template>
                            <div class="bg-white rounded h-100 shadow-sm">
                                <div class="vstack h-100">
                                    <div class="position-relative">
                                        <b-img class="w-100 rounded-top object-fit-cover" :src="product.imageLink || '/images/produto-sem-imagem.png'" @error="onImageError(product)" />
                                        <div class="position-absolute top-0 end-0 p-4 vstack gap-2" v-if="!product.hidden">
                                            <!-- <div v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" role="button" v-if="!user.channelConfig.modules.productsFromIntegration">
                                                <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                    <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                                    <b-icon stacked icon="pencil-fill" scale="0.5" variant="white"></b-icon>
                                                </b-iconstack>
                                            </div> -->
                                            <div v-b-tooltip.hover.bottom="{ variant: 'warning', customClass: 'tooltip-text-white top-0', boundary: 'document' }" :title="product.hidden ? 'Desocultar' : 'Ocultar'" role="button" v-b-modal.hideProduct @click="selectProduct(product, true)">
                                                <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                    <b-icon stacked icon="circle-fill" variant="warning"></b-icon>
                                                    <b-icon stacked icon="eye-slash" scale="0.5" variant="white"></b-icon>
                                                </b-iconstack>
                                            </div>
                                            <div v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'text-white top-0', boundary: 'document' }" title="Editar" role="button" v-b-modal.productEdit @click="selectProduct(product, true)">
                                                <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                    <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                                    <b-icon stacked icon="pencil-fill" scale=".4" variant="white"></b-icon>
                                                </b-iconstack>
                                            </div>
                                            <div v-b-tooltip.hover.bottom.v-info="{ customClass: 'text-white top-0', boundary: 'document' }" title="Visualizar" role="button" v-b-modal.productDetails @click="selectProduct(product)">
                                                <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                    <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                                                    <b-icon stacked icon="info" scale=".75" variant="white"></b-icon>
                                                </b-iconstack>
                                            </div>
                                            <div v-b-tooltip.hover.bottom.v-danger="{ customClass: 'text-white top-0', boundary: 'document' }" title="Remover" role="button" v-if="!user.channelConfig.modules.productsFromIntegration">
                                                <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                                                    <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                                                    <b-icon stacked icon="trash" scale="0.5" variant="white"></b-icon>
                                                </b-iconstack>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vstack p-3 gap-2 justify-content-end">
                                        <div class="box-clamp-1 fw-semibold fs-6 text-secondary">
                                            {{ product.displayTitle }}
                                        </div>
                                        <div class="vstack lh-1 text-secondary" v-if="product.salePrice">
                                            <div class="smaller-text">
                                                De: 
                                                <span class="text-decoration-line-through">
                                                    {{ product.price }}
                                                </span>
                                            </div>
                                            <div>
                                                <span class="fw-bold fs-5">
                                                    R$ {{ product.salePrice }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="fw-bold fs-5 text-secondary lh-1" v-else>
                                            R$ {{ product.price }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-between align-items-center px-2 py-3 bg-white" v-if="products.count > 10">
                    <b-pagination class="m-0"
                        :total-rows="products.count"
                        v-model="currentPage"
                        :per-page="perPage"
                        @change="pageChange"
                    ></b-pagination>
                    <span class="pagdescription">
                        <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{ (currentPage * perPage > products.count) ? products.count : currentPage * perPage }} de {{ products.count }} produtos</div>
                    </span>
                </div>
            </div>
            <div class="bg-white rounded text-center text-secondary shadow-sm p-3" v-else>
                Nenhum produto encontrado!
            </div>
        </div>
        <b-modal id="hideProduct" ref="hideProduct" :title="`${selectedProduct.hidden ? 'Desocultar' : 'Ocultar'} Produto`" hide-footer size="sm" header-class="border-0 p-0 px-3" body-class="p-0" v-if="selectedProduct">
            <div class="border-bottom border-top text-secondary smaller-text p-3">
                Tem certeza de que deseja {{ selectedProduct.hidden ? 'desocultar' : 'ocultar' }} este produto?
            </div>
            <div class="h-stack p-3">
                <b-button type="submit" class="modal-btn modal-btnsearch d-inline-flex align-items-center gap-2" @click="hideProduct()">
                    <b-spinner small v-if="updatingProduct"></b-spinner>
                    {{ selectedProduct.hidden ? 'Desocultar' : 'Ocultar' }}
                </b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('hideProduct')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="productDetails" ref="productDetails" title="Detalhes do Produto" size="lg" hide-footer header-class="p-0 px-3" v-if="selectedProduct">
            <b-row class="m-0 gap-3">
                <b-col class="p-0">
                    <b-img class="w-100 rounded-top" :src="selectedProduct.imageLink || '/images/produto-sem-imagem.png'" @error="onImageError(selectedProduct)" />
                </b-col>
                <b-col class="p-0">
                    <div class="vstack gap-3">
                        <div class="vstack gap-1 lh-1 text-secondary">
                            <div class="fw-semibold fs-5 text-uppercase">
                                {{ selectedProduct.displayTitle }}
                            </div>
                            <div class="smaller-text">
                                CÓD: {{ selectedProduct.id }}
                            </div>
                        </div>
                        <span class="text-secondary">
                            <div class="vstack lh-1" v-if="selectedProduct.salePrice">
                                <div class="smaller-text">
                                    De: 
                                    <span class="text-decoration-line-through">
                                        {{ selectedProduct.price }}
                                    </span>
                                </div>
                                <div>
                                    <span class="fw-bold fs-4">
                                        R$ {{ selectedProduct.salePrice }}
                                    </span>
                                </div>
                            </div>
                            <div class="fw-bold fs-4 lh-1" v-else>
                                R$ {{ selectedProduct.price }}
                            </div>
                        </span>
                        <div class="vstack text-secondary">
                            <div class="fw-semibold">
                                Descrição
                            </div>
                            <div>
                                {{ selectedProduct.description }}
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="productEdit" ref="productEdit" title="Editar Produto" size="lg" hide-footer header-class="p-0 px-3" v-if="selectedProduct">
            <b-row class="m-0 gap-3">
                <b-col class="p-0 align-content-center">
                    <b-img class="w-100 rounded-top" :src="selectedProduct.imageLink || '/images/produto-sem-imagem.png'" @error="onImageError(selectedProduct)" />
                </b-col>
                <b-col class="p-0">
                    <b-form @submit.prevent="editProduct(selectedProduct)" class="vstack gap-4">
                        <div class="vstack gap-2 lh-1 text-secondary">
                            <b-form-group label="Título:" label-class="fw-semibold">
                                <b-form-input v-model="selectedProduct.displayTitle" placeholder="Título" />
                            </b-form-group>
                            <b-form-group label="Código:" label-class="fw-semibold">
                                <b-form-input v-model="selectedProduct.id" placeholder="Código" />
                            </b-form-group>
                            <b-form-group label="Preço (R$):" label-class="fw-semibold">
                                <b-form-input v-model="selectedProduct.price" placeholder="Preço" />
                            </b-form-group>
                            <b-form-group label="Preço em promoção (Caso haja promoção):" label-class="fw-semibold">
                                <b-form-input v-model="selectedProduct.salePrice" placeholder="Preço em promoção" />
                            </b-form-group>
                            <b-form-group label="Descrição:" label-class="fw-semibold">
                                <b-form-textarea v-model="selectedProduct.description" placeholder="Descrição" no-resize rows="5" />
                            </b-form-group>
                            <b-form-group label="Tags:" label-class="fw-semibold">
                                <b-form-tags
                                    class="inputKeywords border-grey"
                                    add-button-text="Adicionar"
                                    add-button-variant="primary"
                                    duplicate-tag-text="Tag duplicada"
                                    tag-remove-label="Remover Tag"
                                    tag-removed-label="Tag removida"
                                    invalid-tag-text="Tag Inválida"
                                    placeholder="Adicionar Tag"
                                    v-model="selectedProduct.keywords"
                                />
                            </b-form-group>
                        </div>
                        <div class="hstack gap-2">
                            <b-button type="submit" class="px-5 d-inline-flex align-items-center gap-2 rounded-pill text-white" variant="green">
                                <b-spinner small v-if="editingProduct"></b-spinner>
                                Salvar
                            </b-button>
                            <b-button class="px-5 rounded-pill text-white" variant="red" @click="$bvModal.hide('productEdit')">Cancelar</b-button>
                        </div>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="hideCategory" ref="hideCategory" :title="`Ocultar Categoria/Subcategoria`" hide-footer  header-class=" py-1" body-class="p-0">
            <div class="vstack gap-3 p-3">
                <div class="hstack gap-3 align-items-end">
                    <b-form-group class="w-100" label="Categoria:">
                        <b-form-select class="text-secondary w-100 rounded-pill p-2 border-grey" v-model="selectedCategoryToHide" @change="selectedSubcategoryToHide = null">
                            <b-form-select-option :value="null">
                                Todas
                            </b-form-select-option>
                            <b-form-select-option :value="category" v-for="category in categories.filter(el => !el.topCategoryId)" :key="category._id">
                                {{ category.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <div v-b-tooltip.hover.bottom="{ variant: selectedCategoryToHide.hidden ? 'primary' : 'warning', customClass: 'tooltip-text-white top-0', boundary: 'document' }" :title="selectedCategoryToHide.hidden ? 'Desocultar' : 'Ocultar'" role="button" @click="markCategoryToHide(selectedCategoryToHide)" v-if="selectedCategoryToHide">
                        <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                            <b-icon stacked icon="circle-fill" :variant="selectedCategoryToHide.hidden ? 'primary' : 'warning'"></b-icon>
                            <b-icon stacked :icon="selectedCategoryToHide.hidden ? 'eye' : 'eye-slash'" scale="0.5" variant="white"></b-icon>
                        </b-iconstack>
                    </div>
                </div>
                <div class="hstack gap-3 align-items-end">
                    <b-form-group class="w-100" label="Subcategoria:" v-if="selectedCategoryToHide">
                        <b-form-select class="text-secondary w-100 rounded-pill p-2 border-grey" :disabled="!selectedCategoryToHide" v-model="selectedSubcategoryToHide" v-if="!categories.filter(el => el.topCategoryId == selectedCategoryToHide._id).length">
                            <b-form-select-option :value="0">
                                Nenhuma subcategoria encontrada!
                            </b-form-select-option>
                        </b-form-select>
                        <b-form-select class="text-secondary w-100 rounded-pill p-2 border-grey" :disabled="!selectedCategoryToHide" v-model="selectedSubcategoryToHide" v-else>
                                <b-form-select-option :value="subcategory" v-for="subcategory in categories.filter(el => el.topCategoryId == selectedCategoryToHide._id)" :key="subcategory._id">
                                    {{ subcategory.name }}
                                </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <div v-b-tooltip.hover.bottom="{ variant: selectedSubcategoryToHide.hidden ? 'primary' : 'warning', customClass: 'tooltip-text-white top-0', boundary: 'document' }" :title="selectedSubcategoryToHide.hidden.hidden ? 'Desocultar' : 'Ocultar'" role="button" @click="markCategoryToHide(selectedSubcategoryToHide)" v-if="selectedSubcategoryToHide">
                        <b-iconstack class="darkenTextOnHover" font-scale="2.5">
                            <b-icon stacked icon="circle-fill" :variant="selectedSubcategoryToHide.hidden ? 'primary' : 'warning'"></b-icon>
                            <b-icon stacked :icon="selectedSubcategoryToHide.hidden ? 'eye' : 'eye-slash'" scale="0.5" variant="white"></b-icon>
                        </b-iconstack>
                    </div>
                </div>
                <div class="hstack gap-3">
                    <b-button type="submit" class="px-5 d-inline-flex align-items-center gap-2 rounded-pill text-white" variant="green" @click="hideCategories()">
                        <b-spinner small v-if="updatingProduct"></b-spinner>
                        Salvar
                    </b-button>
                    <b-button class="px-5 rounded-pill" @click="$bvModal.hide('hideCategory')">Fechar</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'user'
    ],
    mounted: function() {
        this.getAllProductsCount()
        this.getCatalogCategories()
        this.onCloseModal()
    },
    data() {
        return {
            categories: [],
            selectedCategoryId: null,
            selectedSubcategoryId: null,
            selectedCategoryToHide: null,
            selectedSubcategoryToHide: null,
            products: [],
            filters: {},
            loadingProducts: false,
            hasLoaded: false,
            selectedProduct: null,
            updatingProduct: false,
            editingProduct: false,
            currentPage: 1,
            perPage: 12,  
            allProductsCount: 0
        }
    },
    methods: {
        async getCatalogCategories() {
            const resp = await api.getCatalogCategories(this.user.channelId || this.user.roleId)
            // console.log("resp",resp)
            if(resp?.result?.statusCode == 200 && resp.result.categories?.length) {
                resp.result.categories.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(async el => {
                    if(el.visible)
                        this.categories.push(el)
                })
            }
        },
        async getAllProductsCount() {
            const resp = await api.getAllProductsCount(this.user.channelId || this.user.roleId)
            // console.log("resp", resp)
            if(resp.statusCode == 200) {
                this.allProductsCount = resp.count
            }
        },
        async getProducts(page = 1) {
            if(this.selectedCategoryId && !this.selectedCategoryId) {
                this.$emit("msg", {
                    text: "Subcategoria não selecionada!",
                    success: false
                })
            } else {
                this.loadingProducts = true
                this.hasLoaded = true
    
                const resp = await api.getCatalogProductsPagination(this.user.channelId || this.user.roleId, this.selectedSubcategoryId ?? 'all', this.filters, page)
                // console.log("resp",resp)
                if(resp.statusCode == 200) {
                    this.products = resp.products.map(el => {
                        el.keywords = el.keywords?.split(",")
                        return el
                    })
                    this.products.count = resp.count
                    let category = this.categories.find(el => el._id == this.selectedCategoryId)
                    if(category?.hidden) {
                        this.products.categoryIsHidden = true
                    } else if(this.selectedSubcategoryId) {
                        category = this.categories.find(el => el._id == this.selectedSubcategoryId)
                        if(category?.hidden) {
                            this.products.categoryIsHidden = true
                        }
                    }
                } else {
                    this.products = []
                    this.products.count = 0
                }
    
                this.loadingProducts = false

                if(this.currentPage != page)
                    this.currentPage = page
            }   
        },
        selectProduct(product, onNewObject = false) {
            if(onNewObject)
                this.selectedProduct = Object.assign({}, product)
            else
                this.selectedProduct = product
        },
        async hideProduct(productId = null, hidden = null) {
            this.updatingProduct = true
            if(!productId)
                productId = this.selectedProduct._id

            if(hidden == null) {
                hidden = !this.selectedProduct.hidden
            }
            
            // console.log({ hidden })

            const resp = await api.updateProduct(this.user.channelId || this.user.roleId, { _id: productId, hidden })
            // console.log("resp update",resp)
            
            let msg
            if(resp.statusCode == 200 || resp.result?.statusCode == 200) {
                this.selectedProduct.hidden = hidden
                msg = {
                    text: "Produto ocultado com sucesso!",
                    success: true
                }
                this.$bvModal.hide("hideProduct")
            } else {
                msg = {
                    text: "Ocorreu um erro!",
                    success: false
                }
            }

            this.updatingProduct = false

            if(msg)
                this.$emit("msg", msg)
        },
        async editProduct(product) {
            if (product) {
                this.editingProduct = true

                const keywords = typeof product.keywords === "object" ? product.keywords.join(",") : product.keywords

                const resp = await api.updateProduct(this.user.channelId || this.user.roleId, { ...product, keywords })

                this.editingProduct = false

                let msg

                if(resp.statusCode == 200 || resp.result?.statusCode == 200) {
                    this.getProducts(this.selectedPage)

                    msg = {
                        text: "Produto editado com sucesso!",
                        success: true
                    }

                    this.$bvModal.hide("productEdit")
                } else {
                    msg = {
                        text: "Ocorreu um erro!",
                        success: false
                    }
                }

                if(msg)
                    this.$emit("msg", msg)
            }
        },
        async hideCategories() {
            let categories = []

            if(this.selectedCategoryToHide?.markedToHide) {
                categories.push(this.selectedCategoryToHide)
            }

            if(this.selectedSubcategoryToHide?.markedToHide) {
                categories.push(this.selectedSubcategoryToHide)
            }

            if(categories.length) {
                let msg

                for (let category of categories) {

                    const resp = await api.updateCatalogCategory(this.user.channelId || this.user.roleId, {
                        _id: category._id,
                        hidden: category.hidden
                    })

                    if(resp.statusCode == 200 || resp.result.statusCode == 200) {
                        msg = {
                            text: "Categoria ocultada com sucesso!",
                            success: true
                        }
                        this.$bvModal.hide("hideCategory")

                        if(category._id == this.selectedCategoryId || (category._id == this.selectedSubcategoryId && !this.selectedCategoryToHide?.hidden)) {
                            console.log('category.hidden', category.hidden)
                            this.products.categoryIsHidden = category.hidden
                        }
                    } else {
                        msg = {
                            text: "Ocorreu um erro!",
                            success: false
                        }
                    }

                }

                if(msg)
                    this.$emit("msg", msg)
            }
        },
        markCategoryToHide(category) {
            category.markedToHide = !category.markedToHide
            category.hidden = !category.hidden
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getProducts(page)
            }
        },
        onImageError(product) {
            product.imageLink = "/images/produto-sem-imagem.png"
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                if(modalId == "hideCategory") {
                    this.selectedSubcategoryToHide = null
                    this.selectedCategoryToHide = null
                }
            })
        },
    }
}
</script>

<style scoped>
    @media (width > 768px) {
        .productsCol:nth-child(4n + 1) {
            padding-left: 0 !important;
            padding-right: 0.75em !important;
        }
        .productsCol:nth-child(4n - 2) {
            padding-right: 0.5em !important;
            padding-left: 0.25em !important;
        }
        .productsCol:nth-child(4n - 1) {
            padding-right: 0.25em !important;
            padding-left: 0.5em !important;
        }
        .productsCol:nth-child(4n) {
            padding-left: 0.75em !important;
            padding-right: 0 !important;
        }
    }
    @media ( 576px <= width <= 768px) {
        .productsCol:not(:nth-child(2n)) {
            padding-right: 1.25em !important;
        }
    }
</style>